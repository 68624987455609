export const imgurToken = "15a9ef2849f6bbc0a64562beee35f6f38bfee28a";
export const firebaseConfig = {
	    apiKey: "AIzaSyCt0ol7v2VDX7R6jscM36LfnUWsh_mv27E",
	    authDomain: "irl-website-205be.firebaseapp.com",
	        databaseURL: "https://irl-website-205be.firebaseio.com",
		        projectId: "irl-website-205be",
			    storageBucket: "irl-website-205be.appspot.com",
				    messagingSenderId: "646408941433",
				        appId: "1:646408941433:web:08075a3f03ac15f66cc9a8",
					        measurementId: "G-RPEB2X6613"
							  };
